/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import {globalHistory} from '@reach/router';
import { NotificationProvider } from './src/context/AddItemNotificationProvider';

export const wrapRootElement = ({ element }) => (
  <NotificationProvider>{element}</NotificationProvider>
);


export const onInitialClientRender = () => {
  window.localStorage.setItem('base_url', window.location.origin);
  window.localStorage.setItem('site_url', window.location.href);
  window.addEventListener('popstate', () =>{
    if(document.referrer !== ''){
          window.location.href = window.location.href
      }else{
          window.location.href = '/';
      }
    // window.location.href = window.location.href
  }
  );
  globalHistory._onTransitionComplete();
}

window.addEventListener('beforeunload', () => {
  console.log('User clicked back button');
   if(document.referrer && document.referrer !== '' || document.referrer !== undefined){
        window.location.href = window.location.href
      }else{
          window.location.href = '/';
      }
});
window.addEventListener('hashchange', event => {
  console.log('Fragment identifier or URL changed');
});
export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
}

export const onRouteUpdate = ({location})=>{
  if(location.pathname === '/invalid-route'){
    window.history.replaceState(null, null, '/404.html');
    window.location.reload();
  }
};